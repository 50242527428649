// Locales
import I18n from '../../../../i18n';
import ProgressBarSeeker from './progressBarSeeker';

const ProgressBar = ({
  globalProgress,
  globalSequenceTime,
  videos,
  styles,
  startLimitPercent,
  endLimitPercent,
  startLimit,
  endLimit,
  onForwardAndBackward,
}) => {
  return (
    <div className="progress-section" style={styles}>
      <div
        className="heading-3 upper text"
        dangerouslySetInnerHTML={{ __html: I18n.t('practice.progressLevel') }}
      ></div>
      <ProgressBarSeeker
        globalProgress={globalProgress}
        globalSequenceTime={globalSequenceTime}
        videos={videos}
        startLimitPercent={startLimitPercent}
        endLimitPercent={endLimitPercent}
        startLimit={startLimit}
        endLimit={endLimit}
        onForwardAndBackward={onForwardAndBackward}
      ></ProgressBarSeeker>
      <div className="heading-3 upper percent">
        {Math.round((globalProgress / globalSequenceTime) * 100)}%
      </div>
    </div>
  );
};

export default ProgressBar;
