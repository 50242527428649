import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Icon } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

import Topbar from '../../../../components/navbar/topbar';

import OButton from '../../../../styled/components/button';
import { InnerWrapper, Separator } from '../../../../styled/components/wrappers';

import { LinkWrapper } from './styled';

//styles
import '../../profile_new.scss';

import I18n from '../../../../../i18n';

class WalletLinkRef extends Component {

    constructor(props) {

        super(props)
        this.state = {
            copiedLink: false,
        };

    }

    onCopyLink = copiedLink => this.setState({ copiedLink });

    render() {

        const { userSession, customSessionStats, bioMetricMatrix } = this.props;
        const { copiedLink } = this.state;
        const showAffiliateLink = userSession.name && userSession.surname && (customSessionStats.userCustomSessionsCount || 0) > 0 && (bioMetricMatrix.progress || 0) >= 100;
        const linkValue = showAffiliateLink ? `${ window.location.origin }/#/?userIdRef=${ userSession._id }` : 'Enlace no disponible';

        const buttonIconProps = {
            icon:true,
            labelPosition:'right'
        };

        const olStyle = { marginBottom: '10px' };

        return (
            <div className="p-wrapper">
                <Topbar { ...this.props } showProfile={ false } text={ I18n.t('wallet.linkRef') } invert={ true } callback={ ()=> this.props.history.push(`/profile/account`) } />
                <Separator xL/>
                <InnerWrapper>
                    <div className="i-back" onClick={ () => this.props.history.push('/profile/account') }><Icon name="angle left" /><span>{ I18n.t('actions.back') }</span></div>
                    <Separator/>
                    <p className="heading-2 regular">Invita a un amigo a usar Yogabot APP y <strong>gana 50 YGB</strong></p>
                    <p className="heading-3 regular">Cuando tu amigo (referido) complete su <strong>reto de iniciación</strong>, ambos ganarán 50 YGB</p>
                    <p className="paragraph regular">¿Cómo funcionan las referencias en Yogabot?</p>
                    <ol>
                        <li className="paragraph regular" style={ olStyle }>¿Tienes amigos que practican yoga? Sugiéreles una experiencia de práctica 100% personalizada.</li>
                        <li className="paragraph regular" style={ olStyle }>Invítales a probar Yogabot mediante tu enlace único de referencia. Copia tu enlace y compártelo por whatsapp, telegram, instagram, e-mail, etc. Te recomendamos incluir una nota de recomendación personal.</li>
                        <li className="paragraph regular" style={ olStyle }>Los nuevos usuarios referidos por ti disfrutarán 15 días de Yogabot Premium 100% GRATIS, y un reto de iniciación.</li>
                        <li className="paragraph regular" style={ olStyle }>Cuando un usuario complete su reto de iniciación, ambos ganarán 50 YGB. Los nuevos usuarios suelen completar su reto de iniciación en 6 días, como promedio.</li>
                        <li className="paragraph regular" style={ olStyle }>Los 50 YGB se agregarán de forma automática a tu Wallet en concepto de comisión por afiliación</li>
                    </ol>
                    <p className="paragraph regular">IMPORTANTE: El <strong>enlace único de referencia</strong> está disponible para usuarios de Yogabot que cumplan las siguientes condiciones:</p>
                    <ul>
                        <li className="paragraph regular" style={ olStyle }>Perfil de usuario completo {(userSession.name && userSession.surname) ? <FontAwesomeIcon icon={faThumbsUp} color="#1fd5b9" /> : <FontAwesomeIcon icon={faThumbsDown} color="#30425a" />}</li>
                        <li className="paragraph regular" style={ olStyle }>Matriz Biométrica actualizada {(bioMetricMatrix.progress || 0) >=100 ? <FontAwesomeIcon icon={faThumbsUp} color="#1fd5b9" /> : <FontAwesomeIcon icon={faThumbsDown} color="#30425a" />}</li>
                        <li className="paragraph regular" style={ olStyle }>Hayan completado al menos <strong>1 práctica personalizada</strong> en los últimos 30 días. {(customSessionStats.userCustomSessionsCount || 0) > 0 ? <FontAwesomeIcon icon={faThumbsUp} color="#1fd5b9" /> : <FontAwesomeIcon icon={faThumbsDown} color="#30425a" />}</li>
                    </ul>
                    <Separator/>
                    <LinkWrapper>
                        <span className="paragraph regular" style={{marginRight: '10px'}}>{linkValue}</span>
                        <CopyToClipboard text={linkValue} onCopy={ () => this.onCopyLink(true) }>
                            <OButton disabled={!showAffiliateLink} selected upper color="#1fd5b9" {...(copiedLink ? buttonIconProps : {})} type="button" size="small" >
                                <span>{copiedLink ? I18n.t('actions.copiedLink') : I18n.t('actions.copyLink')}</span>
                                {copiedLink && <Icon name="check" />}
                            </OButton>
                        </CopyToClipboard>
                    </LinkWrapper>
                </InnerWrapper>
            </div>
        );

    }

}

const mapStateToProps = state => {

    return {
        userSession: _.get(state, 'auth.userSession', {}),
        bioMetricMatrix: _.get(state.bioMetricMatrix, 'bioMetricMatrixData', {}),
        customSessionStats: _.get(state, 'customSessionStats', {})
    };

};

export default connect(mapStateToProps, {})(WalletLinkRef);