
//libraries and components libraries
import _ from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import Slider from 'react-slick';
import I18n from '../../../i18n';

//components
import Footer from '../../components/footer';
import GradientNav from '../../components/navbar/gradient';
import SubMenu from '../../components/navbar/submenu';
import TopBar from '../../components/navbar/topbar';
import DiscountElement from '../../components/cards/components/discountElement';
import Card from '../../components/cards/components/card';
import { CartDetails } from './components';

//styled components
import OButton from '../../styled/components/button';

//redux actions
import { removeToCart, addToCart, getMarketplace } from '../../../redux-store/cart';
import { openLoader, closeLoader } from '../../../redux-store/loader';
import { openGenericModal, closeGenericModal } from '../../../redux-store/genericModal';

//models
import PaymentModel from '../../../data/models/payment/payment';

//utils and config
import { toMMSS } from '../../../utils/formatTime';

//css
import './cart.scss';

function PrevArrow(props) {

    const { onClick, className } = props;

    return (
      <button type="button" className={"slick-custom-btn-primary prev-a " + className} onClick={onClick}>
        <svg alt="" color="white" role="img" version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" style={{ height: '40px', width: '40px', zIndex: 'auto' }}>
          <path fill="#c1c1c1" d="M22.324 28.008c.537.577.355 1.433-.326 1.809a1.44 1.44 0 0 1-.72.183c-.398 0-.786-.151-1.048-.438L10.06 18.588a1.126 1.126 0 0 1 0-1.555L20.233 6.09c.438-.468 1.198-.564 1.767-.25.681.377.863 1.23.325 1.808l-9.446 10.164 9.446 10.196zM11.112 17.615a.31.31 0 0 1 0 .391l.182-.195-.182-.196zM21.308 7.094c-.01-.006-.053 0-.029-.027a.07.07 0 0 0 .029.027zm-.025 21.499a.95.95 0 0 1-.006-.008l.006.008z"></path></svg>
      </button>
    );

}

function NextArrow(props) {

    const { onClick, className } = props;

    return (
      <button type="button" className={"slick-custom-btn-primary next-a " + className} onClick={onClick}>
        <svg alt="" color="white" role="img" version="1.1" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" style={{ height: '40px', width: '40px', zIndex: 'auto' }}>
          <path fill="#c1c1c1" d="M13.065 7.65c-.538-.578-.355-1.433.325-1.81a1.44 1.44 0 0 1 .72-.182c.398 0 .786.15 1.048.437L25.327 17.07a1.126 1.126 0 0 1 0 1.555L15.155 29.568c-.438.468-1.198.563-1.767.25-.681-.377-.863-1.23-.325-1.809l9.446-10.164L13.065 7.65zm11.211 10.393a.31.31 0 0 1 0-.391l-.181.194.181.197zM14.081 28.564c.01.006.053 0 .028.027a.07.07 0 0 0-.028-.027zm.024-21.5a.95.95 0 0 1 .007.008l-.007-.007z" ></path></svg>
      </button>
    );

}

const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 5,
    arrows: true,
    slidesToScroll: 1,
    mobileFirst: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerMode: false,
    infinite: false,
    responsive: [{
        breakpoint: 1110,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 860,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 720,
        settings: {
            arrows: false,
            slidesToShow: 3,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 560,
        settings: {
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 1
        }
    }]
};

class Cart extends Component {

    constructor(props) {

        super(props);

        this.state = {
            swiped: false,
            schoolCredits: []
        };

        this.productsWithCreditAppliedRef = [];

    }

    componentDidMount() {

        this.getSchoolProductCredit();

    }

    getSchoolProductCredit = async () => {

        const { openLoader, closeLoader } = this.props;

        try {

            openLoader();
            const responsePayment = await PaymentModel.getSchoolProductCredit();

            if (!_.isEmpty(responsePayment.data)) {

               this.setState({schoolCredits : _.filter(responsePayment.data, ({paid}) => !paid)});

            }

        } catch (error) {


        } finally {

            closeLoader();

        }

    }

    onAddToCart = (e, added, id) => {

        if (this.state.swiped) {

            e.stopPropagation();
            e.preventDefault();
            this.setState({swiped: false});

        } else if (!added) {

            this.addToCart(id);

        }

    }

    addToCart = id => {

        const { addToCart, products } = this.props;

        if (window.cordova && _.size(_.filter(products, ({ added }) => added)) > 9) {

            const { openGenericModal, closeGenericModal } = this.props;

            openGenericModal({
                type: 'simple',
                title: {
                    text: I18n.t('messages.toast'),
                    classes: ['heading-2']
                },
                description: {
                    text: I18n.t(`${ window.device.platform }.maxNumberOfSchoolProducts`, { maxProducts: 10 }),
                    classes: ['paragraph', 'regular']
                },
                buttons: [{
                    text: I18n.t('actions.understood'),
                    callback: closeGenericModal,
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: true,
                        upper: true
                    }
                }]
            });

        } else {

            addToCart(id);

        }

    }

    goTo = (e, id) => {

        if (this.state.swiped) {

            e.stopPropagation();
            e.preventDefault();
            this.setState({swiped: false});

        } else {

            e.target.nodeName !== 'BUTTON' && e.target.nodeName !== 'SPAN' && this.props.history.push(`/studio/details/${id}`);

        }

    }

    handleSwiped = () => {

        let that = this;
        this.setState({ swiped:true }, () => {

            clearInterval(that.timeout);
            that.timeout = setTimeout(() => {

                that.setState({ swiped: false });

            }, 500);

        });

    }

    openModal = () => {

        const { openGenericModal, closeGenericModal } = this.props;

        openGenericModal({
            type: 'simple',
            title:{
                text: I18n.t('messages.alert'),
                classes: ['heading-2']
            },
            description:{
                text: I18n.t('messages.errorAtFinalizeBuyInStudio'),
                classes: ['paragraph', 'regular']
            },
            buttons:[
                {
                    text: I18n.t('actions.understood'),
                    callback: ()=> {

                        closeGenericModal();

                    },
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: true,
                        upper: true
                    }
                }

            ]
        });

    }

    onFinishBuy = async () => {

        const { schoolCredits } = this.state;
        const { products , openLoader, closeLoader, userSession } = this.props;
        const addedProducts = _.filter(products, ({ added }) => added);

        let creditIdsUsed = [];
        let productsId = [];
        let hasError = false;

        if (schoolCredits.length > 0 ) {

              _.each(this.productsWithCreditAppliedRef, ({ creditId, productId }) => {

                creditIdsUsed.push(creditId);
                productsId.push({productId: productId, price: 0});

            });

            try {

                openLoader();
                //exchange the spend credits
                await PaymentModel.updateSchoolProductCredit(creditIdsUsed);
                //create the payment
                await PaymentModel.createUserPayment({
                    amount: creditIdsUsed.length,
                    currency: 'School Product',
                    description: I18n.t('checkout.productsBuyed'),
                    type: 'product',
                    platform: 'yogabot',
                    payerId: userSession._id,
                    orderId: 'Yogabot',
                    products: productsId
                });

            } catch (error) {

                hasError = true;

            } finally {

                if (!hasError) {

                    await this.props.getMarketplace();

                    if (addedProducts.length > schoolCredits.length) {

                        this.props.history.push('/checkout');

                    } else {

                        this.props.history.push('/studio');

                    }

                } else {

                    this.openModal();

                }

                closeLoader();

            }

        }  else {

            this.props.history.push('/checkout');

        }

    };

    onRemoveItem = id => this.props.removeToCart(id);

    applyProductCredit = addedProducts => {

        const { schoolCredits } = this.state;

        let creditsToApply = schoolCredits.length;
        let orderedByMaxPrice = _.chain(addedProducts)
            .map(({ _id, prices, discountedPrices }) => {

                return {
                    productId: _id,
                    finalPrice : (discountedPrices && !_.isEmpty(discountedPrices)) ? discountedPrices.EUR : prices.EUR,
                }

            })
            .orderBy(['finalPrice'], ['desc'])
            .map(item => {

                return {
                    ...item,
                    creditApplied: --creditsToApply
                }

            })
            .filter(item => item.creditApplied >= 0)
            .map((item, index) => {
                return {
                    ...item,
                    creditId: schoolCredits[index]._id
                }
            })
            .value();

        this.productsWithCreditAppliedRef = _.clone(orderedByMaxPrice);

        return orderedByMaxPrice;

    }

    setCurrencyView = (currency, price) => currency === 'USD' ? <span>${ price[currency]?.toFixed(2) }</span> : <span>{ price[currency]?.toFixed(2) }€</span>

    render() {

        const { schoolCredits } = this.state;
        const { products, currency } = this.props;
        const addedProducts = _.filter(products, ({ added }) => added);
        const relatedProductRefs = _.chain(addedProducts).map(({ relatedProducts }) => relatedProducts ).flatMapDeep().uniq().value();
        const relatedProduct = _.filter(products, ({ _id, buyed, added, published }) => !added && !buyed && published && _.findIndex(relatedProductRefs, id => id === _id )> -1);
        const productsWithCreditApplied = this.applyProductCredit(addedProducts);

        return (
            <React.Fragment>
            <GradientNav active="studio" {...this.props} />
                <div className="s-cart-wrapper">
                    <TopBar callback={() => this.props.history.push('/studio')} {...this.props} showProfile={true} text={''} hasBasket={true} />
                    <div className="inner">
                        <div className="s-cart-flex">
                            <div className="s-cart-list-wrap">
                                <div className="s-cart-header">
                                    <p className="s-cart-header-title">{I18n.t('cart.cart')}</p>
                                    {!_.isEmpty(addedProducts) && <CartDetails
                                        mobile={true}
                                        showSubTotal={schoolCredits.length > 0}
                                        cb={this.onFinishBuy}
                                        productsWithCreditApplied={productsWithCreditApplied}
                                        addedProducts={addedProducts}
                                        currency={currency}
                                    />}
                                    <p className="s-cart-title">{I18n.t('cart.selectedProducts')}</p>
                                </div>
                                <div className="s-cart-list">
                                    {!_.isEmpty(addedProducts) ?
                                        _.map(addedProducts, item => (
                                        <div className="item" key={item._id}>
                                            { (!_.isEmpty(item.discountedPrices) || _.findIndex(productsWithCreditApplied, ({productId}) => productId === item._id) > -1) && <DiscountElement floating style={{marginLeft:'10px'}}>
                                                <span className="paragraph upper">{_.findIndex(productsWithCreditApplied, ({productId}) => productId === item._id) > -1 ? I18n.t('studio.rewarded') :  `${item.discount}% DTO` }</span>
                                            </DiscountElement>}
                                            <div className="item-image">
                                                <img src={item.imageUrl} alt="" />
                                            </div>
                                            <div className="item-content">
                                                <div className="item-time caption regular"><i className="f-icon-icono_17"></i>{ toMMSS(item.duration) }</div>
                                                <div className="item-title paragraph regular">{item.title}</div>
                                                {_.isEmpty(item.discountedPrices) &&

                                                    <React.Fragment>
                                                        {
                                                            _.findIndex(productsWithCreditApplied, ({productId}) => productId === item._id) > -1 ?
                                                                <DiscountElement.DiscountPrice>
                                                                    <p className="caption regular brand-primary-extra-light" style={{margin: 0}}>{I18n.t('studio.previousPrice')} {this.setCurrencyView(currency, item.prices)}</p>
                                                                    <p className="paragraph" style={{margin: 0}}>{I18n.t('studio.currentPrice')} {this.setCurrencyView( currency, {EUR: 0, USD: 0} )}</p>
                                                                </DiscountElement.DiscountPrice> : <div className="item-price paragraph">{this.setCurrencyView(currency, item.prices)}</div>
                                                        }
                                                    </React.Fragment>

                                                }
                                                { !_.isEmpty(item.discountedPrices) &&
                                                    <DiscountElement.DiscountPrice>
                                                        <p className="caption regular brand-primary-extra-light" style={{margin: 0}}>{I18n.t('studio.previousPrice')} {this.setCurrencyView(currency, item.prices)}</p>
                                                        {
                                                            _.findIndex(productsWithCreditApplied, ({productId}) => productId === item._id) > -1 ?
                                                            <p className="paragraph" style={{margin: 0}}>{I18n.t('studio.currentPrice')} {this.setCurrencyView( currency, {EUR: 0, USD: 0} )}</p> :
                                                            <p className="paragraph" style={{margin: 0}}>{I18n.t('studio.currentPrice')} {this.setCurrencyView( currency, item.discountedPrices )}</p>
                                                        }

                                                    </DiscountElement.DiscountPrice>
                                                }
                                                <span className="action-remove" onClick={()=> this.onRemoveItem(item._id)}>{I18n.t('actions.remove')}</span>
                                            </div>
                                        </div>
                                        )) :
                                        <div className="s-cart-empty">
                                            <p className="s-cart-empty-message">{I18n.t('studio.emptyAsanas')}</p>
                                            <OButton className="s-cart-empty-btn" primary color="#fff" upper type="button" onClick={() => this.props.history.push('/studio')}>
                                                <span>{I18n.t('studio.allAsanas')}</span>
                                            </OButton>
                                        </div>
                                    }

                                </div>
                            </div>
                            {!_.isEmpty(addedProducts) &&

                                <CartDetails
                                    desktop={true}
                                    showSubTotal={schoolCredits.length > 0}
                                    cb={this.onFinishBuy}
                                    productsWithCreditApplied={productsWithCreditApplied}
                                    addedProducts={addedProducts}
                                    currency={currency}
                                />
                            }
                        </div>
                        {!_.isEmpty(relatedProduct) &&
                            <div className="rp-wrapper">
                                <div className="rp-header">
                                    <p className="rp-title">{I18n.t('studio.relatedAsanas')}</p>
                                </div>
                                <div className="rp-slider">
                                    <Slider {...settings} onSwipe={this.handleSwiped}>
                                        {
                                            _.map(relatedProduct, (item, index) => (
                                                <Card
                                                    {...item }
                                                    key={item._id + index }
                                                    context="slider"
                                                    currency={currency}
                                                    mainAction={this.goTo}
                                                    onAddToCart={this.onAddToCart}
                                                />
                                            ))
                                        }
                                    </Slider>
                                </div>
                            </div>}
                    </div>
                    <Footer {...this.props} type="branded"/>
                </div>
                <SubMenu active="studio" {...this.props}/>
            </React.Fragment>

        );

    }

}

const mapStateToProps = state => {

    return {
        products: _.get(state,'cart.products', []),
        role: state.role,
        currency: _.get(state, 'auth.userSession.config.currency', {}),
        userSession: _.get(state, 'auth.userSession', {})
    };

}

export default connect(mapStateToProps, { addToCart, removeToCart, openLoader, closeLoader, getMarketplace, openGenericModal, closeGenericModal })(Cart);