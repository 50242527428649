import React, { useState, useEffect, useCallback } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  getStripeCouponPrices,
  hasStripeCouponPromotion,
} from '../../../utils/checkPromotions';
import _ from 'lodash';
import PaymentModel from '../../../data/models/payment/payment';
import { Form, Input } from 'semantic-ui-react';
import I18n from '../../../i18n';
import OButton from '../../styled/components/button';

const StripeCheckoutForm = ({
//   stripe,
  elements,
  periodic,
  amount,
  description,
  currency,
  type,
  products,
  onSuccess,
  onError,
  onSuccesDiscountCode,
}) => {
  const stripe = useStripe();

  const [paymentRequest, setPaymentRequest] = useState(null);

  const [formProcess, setFormProcess] = useState(false);
  const [couponCode, setCouponCode] = useState(null);

  const isValidCoupon = useCallback(
    (couponCode) =>
      hasStripeCouponPromotion(type, couponCode) &&
      !_.isEmpty(couponCode) &&
      _.get(getStripeCouponPrices(), [type, couponCode]),
    [type]
  );

  const paymentMethodHandler = useCallback(
    async (paymentMethod) => {
      if (periodic) {
        const stripePaymentSuscriptionData = {
          type,
          paymentMethodId: paymentMethod.id,
          amount: amount * 100,
          currency: currency,
        };

        if (isValidCoupon(couponCode)) {
          stripePaymentSuscriptionData.coupon = couponCode;
        }

        const subscription = await PaymentModel.createStripePaymentSubscription(
          stripePaymentSuscriptionData
        );

        const userSubscription = await PaymentModel.createUserPayment({
          amount: amount,
          currency: currency,
          description,
          type,
          platform: 'stripe',
          brand: paymentMethod.card.brand,
          last4: paymentMethod.card.last4,
          orderId: subscription.data.code,
        });

        onSuccess(userSubscription);
      } else {
        const paymentIntent = await PaymentModel.createStripePaymentIntent({
          amount: amount * 100,
          currency: currency,
          periodic,
          description,
          paymentMethod: paymentMethod.id,
        });

        stripe
          .confirmCardPayment(paymentIntent.data.client_secret, {
            payment_method: paymentMethod.id,
            receipt_email: paymentIntent.receipt_email,
            intent: paymentIntent.id,
          })
          .then(async (result) => {
            if (result.error) {
              setFormProcess(false);
              throw result.error.message;
            } else if (result.paymentIntent.status === 'succeeded') {
              await PaymentModel.createUserPayment({
                amount,
                currency,
                description,
                type,
                platform: 'stripe',
                brand: paymentMethod.card.brand,
                last4: paymentMethod.card.last4,
                orderId: result.paymentIntent.id,
                products,
              });

              onSuccess();
            }
          });
      }
    },
    [
      amount,
      couponCode,
      currency,
      description,
      isValidCoupon,
      onSuccess,
      periodic,
      products,
      stripe,
      type,
    ]
  );

  useEffect(() => {
    if (stripe && amount && currency) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: currency.toLowerCase(),
        total: {
          label: description || 'Demo total',
          amount: amount * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      // Manejar el evento 'paymentmethod'
      pr.on('paymentmethod', async (event) => {
        try {
          await paymentMethodHandler(event.paymentMethod);
          event.complete('success');
        } catch (error) {
          event.complete('fail');
          setFormProcess(false);
          onError(error);
        }
      });
    }
  }, [
    amount,
    currency,
    description,
    onError,
    onSuccess,
    paymentMethodHandler,
    periodic,
    products,
    stripe,
    type,
  ]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formProcess) {
      setFormProcess(true);

      try {
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement('cardNumber'),
        });

        if (error) {
          setFormProcess(false);
          throw error.message;
        }

        await paymentMethodHandler(paymentMethod);
      } catch (error) {
        setFormProcess(false);
        onError(error);
      }
    }
  };

  const handleCouponValueChange = (couponCode) => {
    setCouponCode(couponCode);

    if (onSuccesDiscountCode)
      onSuccesDiscountCode(
        _.get(getStripeCouponPrices(), [type, couponCode, currency], 0)
      );
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSmoothing: 'antialiased',
        fontSize: '1em',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    hidePostalCode: true,
  };

  return (
    <Form onSubmit={handleSubmit}>
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}
      <Form.Field>
        <label>{I18n.t('payment.cardNumber')}</label>
        <CardNumberElement id="card_number" options={CARD_ELEMENT_OPTIONS} />
      </Form.Field>
      <Form.Group>
        <div className="cl-12">
          <Form.Field>
            <label>{I18n.t('payment.expirationDate')}</label>
            <CardExpiryElement
              id="card_expiry"
              options={CARD_ELEMENT_OPTIONS}
            />
          </Form.Field>
        </div>
        <div className="cl-4">
          <Form.Field>
            <label>CVC</label>
            <CardCvcElement id="card_cvc" options={CARD_ELEMENT_OPTIONS} />
          </Form.Field>
        </div>
      </Form.Group>
      <Form.Field>
        <label>{I18n.t('payment.coupon')}</label>
        <Input
          icon={isValidCoupon(couponCode) ? 'check' : undefined}
          style={{ color: isValidCoupon(couponCode) ? '#1fd5b9' : undefined }}
          value={couponCode}
          placeholder={I18n.t('payment.couponPlaceholder')}
          onChange={(_, input) => handleCouponValueChange(input.value)}
          className="stripe-coupon-code"
        />
      </Form.Field>
      {formProcess ? (
        <OButton type="button" disabled={true} primary color="#fff" upper>
          {I18n.t('payment.process')}
        </OButton>
      ) : (
        <OButton type="submit" disabled={!stripe} primary color="#fff" upper>
          {I18n.t('payment.makePayment')}
        </OButton>
      )}
    </Form>
  );
};

export default StripeCheckoutForm;
