// Third party libraries
import _ from 'lodash';
import React from 'react';
import Fullscreen from 'fullscreen-react';

// Components
import FixedBanner from '../../../components/fixedBanner';
import Footer from '../../../components/footer';
import GradientNav from '../../../components/navbar/gradient';
import TopBar from '../../../components/navbar/topbar';
import SubMenu from '../../../components/navbar/submenu';
import Notification from '../../../components/notificaction';
import PlayerButtons from '../components/playerButtons';
import PlayerTimer from '../components/playerTimer';
import NextSlide from '../components/nextSlide';
import ProgressBar from '../components/progressBar';
import Preambule from '../components/preambule';

// Utils
import UseNoSleep from '../../../../utils/noSleep';
import { TrackingService } from '../../../../utils/TrackingService';
import { changeScreenOrientation, completeSession, showHealthAdvise, onRestart } from '../../../../utils/practice';

// Models
import SessionModel from '../../../../data/models/session/session';

// Locales
import I18n from '../../../../i18n';

// Styles
import '../practice.scss';

class PracticeImage extends React.Component {

    intervalCountDown = 0;
    song = null;
    language = window.atob(this.props.match.params.sequence).split('/')[1];
    startOnPortrait = false;

    constructor(props) {

        super(props);

        this.state = {
            isFull: false,
            currentSlideIndex: 0,
            data: null,
            slideElapsedTime: 0,
            startTimeSlide: 0,
            firstStart: false,
            globalProgress: 0,
            globalSequenceTime: 0,
            showPreambule: false,
            showNextSlide: false,
            status: 'idle',
            startTimePreambule: 0,
            sequence: '',
            userSessionId: '',
            indications: {
                flexoextensionIndicator: 0,
                lateralizationIndicator: 0,
                abductionAductionIndicator: 0,
                muscularTrainIndicator: 0
            }
        };

    }

    componentWillUnmount() {

        clearInterval(this.intervalCountDown);
        clearInterval(window['yogabotIntervalsPracticePreambule']);
        delete window['yogabotIntervalsPracticePreambule'];
        this.props.closeGenericModal();
        this.startOnPortrait = changeScreenOrientation('portrait', this.startOnPortrait);

    }

    componentDidMount() {

        let globalSequenceTime = 0;
        _.each(this.props.data.routine, routine => {

            globalSequenceTime += _.parseInt(routine.duration);

        });

        const indications = {
            flexoextensionIndicator: this.props.bioMetricMatrix.flexoextensionIndicator || 0,
            lateralizationIndicator: this.props.bioMetricMatrix.lateralizationIndicator || 0,
            abductionAductionIndicator: this.props.bioMetricMatrix.abductionAductionIndicator || 0,
            muscularTrainIndicator: this.props.bioMetricMatrix.muscularTrainIndicator || 0
        };

        this.song = new Audio(this.props.data.routine[0].nameAudio);

        this.setState({
            data: this.props.data,
            sequence: this.props.sequence,
            globalSequenceTime,
            indications
        });

        showHealthAdvise(this.props.sessionTime, this.props.openGenericModal, this.props.closeGenericModal);

    }

    tickCountDown = () => {

        this.setState({ slideElapsedTime: this.state.data.routine[this.state.currentSlideIndex].duration - (Math.floor((Date.now() - this.state.startTimeSlide) / 1000)) });

        if (this.state.slideElapsedTime <= 0) {

            let self = this;
            let nextAsanaIndex = this.state.currentSlideIndex;
            nextAsanaIndex++;

            if (nextAsanaIndex < this.state.data.routine.length) {

                clearInterval(self.intervalCountDown);

                this.setState({
                    globalProgress: this.state.globalProgress + (this.state.data.routine[this.state.currentSlideIndex].duration - this.state.slideElapsedTime),
                    currentSlideIndex: nextAsanaIndex,
                    showNextSlide: false,
                    slideElapsedTime: 0,
                    showPreambule: true,
                    startTimePreambule: Date.now()
                }, async () => {

                    try {

                        SessionModel.onAsanaFinish({ userSessionId: this.state.userSessionId, timeElapsed: this.state.data.routine[this.state.currentSlideIndex-1].duration }).then(() => {

                            this.props.getLastWeekTrainingSeconds();

                        });

                    } catch (error) {

                        console.error('error:onAsanaFinish', error);

                    }

                });

            } else {

                this.setState({ globalProgress: this.state.globalSequenceTime, showNextSlide: false, slideElapsedTime: 0, status: 'idle', showPreambule: false });
                clearInterval(this.intervalCountDown);
                completeSession(this.state.userSessionId, this.props.history, this.language, '/practice/result/sequence', this.props.getLastWeekTrainingSeconds);

            }

        } else {

            if (_.get(this.state.data.routine, '[' + (this.state.currentSlideIndex + 1) + '].duration', false) && (this.state.slideElapsedTime <= 10)) {

                if (!this.state.showNextSlide) {

                    if (this.state.data.routine[this.state.currentSlideIndex].duration > 10) {

                        this.song.src = 'https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/asanas/sonidos/ES/next.mp3';
                        this.song.addEventListener('ended', () => {

                            this.song = this.song.cloneNode(true);
                            this.song.src = this.state.data.routine[this.state.currentSlideIndex + 1].nameAudio;
                            this.song.play();

                        }, false);

                        this.song.play();

                    } else if (this.state.data.routine[this.state.currentSlideIndex].duration >= 5) {

                        this.song.src = this.state.data.routine[this.state.currentSlideIndex + 1].nameAudio;
                        this.song.play();

                    }

                    this.setState({ showNextSlide: true });

                }

            }

        }

    }

    startCountDown = () => {

        this.setState({ showPreambule: false });

        if (this.state.currentSlideIndex < this.state.data.routine.length) {

            this.setState({ status: 'playing', slideElapsedTime: this.state.data.routine[this.state.currentSlideIndex].duration, startTimeSlide: Date.now() });
            this.intervalCountDown = setInterval(this.tickCountDown, 1000);

        }

    }

    restart = () => {

        this.setState({ firstStart: false, globalProgress: 0, currentSlideIndex: 0, showNextSlide: false, slideElapsedTime: 0, status: 'idle', showPreambule: false, isFull: false });
        clearInterval(this.intervalCountDown);

    }

    onStart = async () => {

        TrackingService.registerEvent('Practice', 'practiceSequenceStart');

        try {

            const response = await SessionModel.startSession({ sequence: this.state.sequence });

            this.song = new Audio(this.props.data.routine[0].nameAudio);
            this.song.play();

            this.setState({ userSessionId: response.data._id, firstStart: true, isFull: true, showPreambule: true, startTimePreambule: Date.now() });
            this.startOnPortrait = changeScreenOrientation('landscape', this.startOnPortrait);

        } catch (error) {

            console.error('error:onStart:', error);

        }

    }

    onPause = () => {

        clearInterval(this.intervalCountDown);
        this.setState({ status: 'resume', isFull: false });
        this.startOnPortrait = changeScreenOrientation('portrait', this.startOnPortrait);

    }

    onResume = () => {

        let lastTime = this.state.slideElapsedTime;
        this.setState({ isFull: true, status: 'playing', startTimeSlide: Math.floor(Date.now() - ((this.state.data.routine[this.state.currentSlideIndex].duration - lastTime) * 1000)) });
        this.intervalCountDown = setInterval(this.tickCountDown, 1000);
        this.startOnPortrait = changeScreenOrientation('landscape', this.startOnPortrait);

    }

    gotoDiscoverMore = () => this.props.history.push(`/practiceDiscover/targets/${ this.props.match.params.sequence }`);

    render() {

        const { isFull } = this.state;
        const { isAlternative, windowMeassures: { height } } = this.props;

        return (
            <React.Fragment>
                <UseNoSleep />
                <GradientNav active='practice' { ...this.props } />
                { this.state.data ? (
                    <div className='wrapper-practice'>
                        <TopBar { ...this.props } callback={ () => this.props.history.push('/practiceList') } text={ I18n.t('practice.practice') } />
                        <FixedBanner />
                        <div className='inner'>
                            { !isAlternative &&
                                <div className='header-message'>
                                    <div className='header-content'>
                                        <div className='left'>
                                            { this.props.bioMetricMatrix.progress > 0 ?
                                                <p dangerouslySetInnerHTML={{ __html: I18n.t('practice.welcomeMessage', { userName: _.get(this.props,'userName', '') }) }}></p> :
                                                <p dangerouslySetInnerHTML={{ __html: I18n.t('practice.welcomeMessageAlternative', { userName: _.get(this.props,'userName', '') }) }}></p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <Fullscreen isEnter={ isFull } onChange={ isFull => this.setState({ isFull }) }>
                                <div className={ 'wrapper-slides ' + (isFull ? 'fullscreen-enabled' : '') } >
                                    <div style={{ width: '100%', maxWidth: isFull ? `${height * (16 / 9)}px` : '100%' }}>
                                        <div className='wrapper-slide' >
                                            <Preambule
                                                visible={ this.state.showPreambule }
                                                startTimePreambule={ this.state.startTimePreambule }
                                                callback={ this.startCountDown }
                                            />
                                            <div className={ 'content ' + (this.state.showPreambule ? 'blurred': '') }>
                                                <PlayerButtons
                                                    visible={ !this.state.showPreambule }
                                                    status={ this.state.status }
                                                    firstStart={ this.state.firstStart }
                                                    onStart={ this.onStart }
                                                    onRestart={ () => onRestart(this.props.openGenericModal, this.props.closeGenericModal, this.onPause, this.restart, this.onResume) }
                                                    onPause={ this.onPause }
                                                    onResume={ this.onResume }
                                                />
                                                <PlayerTimer
                                                    visible={ !this.state.showPreambule }
                                                    slideElapsedTime={ this.state.slideElapsedTime }
                                                />
                                                <img className='img-slider' src={ this.state.data.routine[this.state.currentSlideIndex].image } alt=''></img>
                                                <NextSlide
                                                    visible={ this.state.showNextSlide }
                                                    currentSlideIndex={ this.state.currentSlideIndex }
                                                    routine={ this.state.data.routine }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fullscreen>
                            <ProgressBar
                                globalProgress={ this.state.globalProgress }
                                globalSequenceTime={ this.state.globalSequenceTime }
                            />
                        </div>
                        <div className='footer-separator'></div>
                        <Footer { ...this.props } type='branded'/>
                    </div>
                ) : '' }
                <SubMenu active='practice' { ...this.props } />
                <Notification type='fixered' />
            </React.Fragment>
        );

    }

}

export default PracticeImage;