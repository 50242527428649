// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector, initialize } from 'redux-form';
import { Form, Responsive } from 'semantic-ui-react';
import QueryString from 'query-string';
import ReCAPTCHA from 'react-google-recaptcha';
import { Trans } from 'react-i18next';

// Componentes
import ErrorMessage from '../../../components/errorMessage';
import CustomInput from '../../../components/form/input';
import CustomCheckBox from '../../../components/form/checkbox';

// Styled components
import OButton from '../../../styled/components/button';
import {
  RegisterWrapper,
  RCols,
  RegisterBox,
  ReferedTitle,
  AuthRefered,
  ReferedPicture,
} from './styled';
import {
  AuthCTALogin,
  AuthIcon,
  AuthHeader,
  AuthTitle,
  AuthRRSS,
  AuthSeparator,
  AuthRRSSTitle,
  AuthRRSSIcon,
  AuthLine,
  BrandIcon,
  AuthMetas,
  AuthMetaTitle,
  AuthMetaDescription,
  YogabotIcon,
} from '../styled';

// Redux
import {
  registerUser,
  thirdPartyloginUser,
} from '../../../../redux-store/auth';
import { getLastWeekTrainingSeconds } from '../../../../redux-store/session';
import { getBiometricMatrix } from '../../../../redux-store/bioMetricMatrix';
import { getChallenges } from '../../../../redux-store/challenges';
import { closeLoader, openLoader } from '../../../../redux-store/loader';
import { getRole } from '../../../../redux-store/role';
import { getAlternativePractices } from '../../../../redux-store/alternativePractices';
import { openWelcomeModal } from '../../../../redux-store/welcomeModal';
import { getMarketplace } from '../../../../redux-store/cart';
import { getCustomSessionStats } from '../../../../redux-store/customSessionStats';

// Models
import SecurityModel from '../../../../data/models/security/security';

// Utils
import { TrackingService } from '../../../../utils/TrackingService';
import { firebaseThirdPartyLogin } from '../../../../utils/firebase';

// Locales
import I18n from '../../../../i18n';

// Assets
import gLogo from '../../../assets/img/g_logo.png';
import fLogo from '../../../assets/img/f_logo.png';
import yLogo from '../../../assets/img/yogabot_icon.svg';
import yLogoLarge from '../../../assets/img/logo_large.svg';

const recaptchaRef = React.createRef();

class RegisterView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      codeError: -1,
      correct: false,
      isCaptchaCorrect: false,
      referrerData: {},
      nickname: '',
      proUserId: '',
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(
      initialize('registerFillForm', {
        email: '',
        password: '',
        tos: false,
        comercialNotifications: false,
      })
    );

    this.getReferrerData();
  }

  getQueryStringReferrer = () => {
    if (localStorage.getItem('ref')) {
      return { nickname: localStorage.getItem('ref') };
    } else if (localStorage.getItem('pro')) {
      return { pro: localStorage.getItem('pro') };
    } else if (_.get(this.props, 'location.search', false)) {
      const UrlQueryStrings = this.props.location.search;
      const queryValues = QueryString.parse(UrlQueryStrings);

      if (queryValues.ref) {
        localStorage.setItem('ref', queryValues.ref);
        return { nickname: queryValues.ref };
      }

      if (queryValues.pro) {
        localStorage.setItem('pro', queryValues.pro);
        return { pro: queryValues.pro };
      }
    } else {
      return {};
    }
  };

  getReferrerData = async () => {
    const { openLoader, closeLoader } = this.props;

    try {
      openLoader();

      const { nickname, pro } = this.getQueryStringReferrer();
      let referrerData = {};
      if (nickname) {
        referrerData = _.get(
          await SecurityModel.getAdvisorInfoByNickName(nickname),
          'data'
        );
        this.setState({ nickname, referrerData });
      } else if (pro) {
        referrerData = _.get(await SecurityModel.getProInfo(pro), 'data');
        this.setState({ pro, referrerData });
      }
    } catch (error) {
    } finally {
      closeLoader();
    }
  };

  gotoLogin = () => this.props.history.push(`/auth/login`);

  onSuccessRegister = async () => {
    const {
      history,
      openLoader,
      closeLoader,
      getChallenges,
      getBiometricMatrix,
      getLastWeekTrainingSeconds,
      getRole,
      getAlternativePractices,
      getMarketplace,
      getCustomSessionStats,
    } = this.props;

    try {
      openLoader();
      await getChallenges();
      await getBiometricMatrix();
      await getLastWeekTrainingSeconds();
      await getRole();
      await getAlternativePractices();
      await getMarketplace();
      await getCustomSessionStats();
      history.push('/profile/account');
      //!Not show welcome modal at the moment
      //this.props.openWelcomeModal();
    } catch (codeError) {
      this.setState({
        hasError: true,
        codeError: 'register-repeat-email',
      });
    } finally {
      closeLoader();

      //Eliminar ref en caso exitoso de registro
      if (localStorage.getItem('ref')) {
        localStorage.removeItem('ref');
      }

      if (localStorage.getItem('pro')) {
        localStorage.removeItem('pro');
      }
    }
  };

  onSelectMode = async () => {
    const { getRole } = this.props;
    try {
      // When registering a user, the default 'explorer' mode is defined
      await SecurityModel.updateGrowChallengeStatus('explorer');
      await getRole();
    } catch (error) {}
  };

  onThirdPartyLogin = async (loginObject) => {
    const { openLoader, closeLoader, thirdPartyloginUser } = this.props;
    const { nickname, pro } = this.state;

    try {
      openLoader();

      if (!_.isEmpty(nickname)) {
        loginObject.ref = nickname;
      } else if (!_.isEmpty(pro)) {
        loginObject.pro = pro;
      } else if (window.localStorage.getItem('userIdRef')) {
        loginObject.userIdRef = window.localStorage.getItem('userIdRef');
      }

      loginObject.platform = !window.cordova ? 'Web' : window.device.platform;

      const user = await thirdPartyloginUser(loginObject);
      if (user.register) {
        TrackingService.registerEvent(
          'Register',
          `sign_up_${loginObject.provider}`
        );
      } else {
        TrackingService.registerEvent('Login', `login_${loginObject.provider}`);
      }

      this.onSelectMode();

      this.onSuccessRegister();
    } catch (codeError) {
      this.setState({ hasError: true, codeError: 'error-login' });
      closeLoader();
    }
  };

  register = async ({ email, password, comercialNotifications }) => {
    const { openLoader, closeLoader, registerUser } = this.props;
    const { nickname, pro } = this.state;

    const recaptchaValue = !window.cordova
      ? recaptchaRef.current.getValue()
      : 'captcha';

    if (_.isEmpty(recaptchaValue)) {
      return false;
    }

    try {
      openLoader();

      const formValues = {
        email: _.trim(email),
        password,
        comercialNotifications,
        platform: !window.cordova ? 'Web' : window.device.platform,
      };

      if (!_.isEmpty(nickname)) {
        formValues.ref = nickname;
      } else if (!_.isEmpty(pro)) {
        formValues.pro = pro;
      } else if (window.localStorage.getItem('userIdRef')) {
        formValues.userIdRef = window.localStorage.getItem('userIdRef');
      }

      await registerUser(formValues);

      TrackingService.registerEvent('Register', 'sign_up');

      this.onSelectMode();

      this.onSuccessRegister();
    } catch (error) {
      this.setState({
        hasError: true,
        codeError: 'register-repeat-email',
      });

      closeLoader();
    }
  };

  renderSocialBlock = () => {
    return (
      <>
        <AuthRRSS
          google
          onClick={() =>
            firebaseThirdPartyLogin('google', this.onThirdPartyLogin)
          }
        >
          <AuthRRSSIcon>
            <img src={gLogo} alt="google" />
          </AuthRRSSIcon>
          <AuthRRSSTitle>{I18n.t('auth.registerWithGoogle')}</AuthRRSSTitle>
        </AuthRRSS>
        <AuthRRSS
          facebook
          onClick={() =>
            firebaseThirdPartyLogin('facebook', this.onThirdPartyLogin)
          }
        >
          <AuthRRSSIcon>
            <img src={fLogo} alt="facebook" />
          </AuthRRSSIcon>
          <AuthRRSSTitle>{I18n.t('auth.registerWithFacebook')}</AuthRRSSTitle>
        </AuthRRSS>
      </>
    );
  };

  /**
   * @desc values can null in case of expired or error otherwise with a token
   * */
  onChangeCaptcha = (value) => this.setState({ isCaptchaCorrect: !!value });

  render() {
    const { hasError, codeError, isCaptchaCorrect, referrerData } = this.state;
    const { tos } = this.props;

    return (
      <>
        <RegisterWrapper className="r-wrapper">
          <RCols>
            <RegisterBox>
              {_.isEmpty(referrerData) && (
                <AuthHeader column>
                  <Responsive minWidth={560}>
                    <AuthIcon w={240} h={50}>
                      <YogabotIcon src={yLogoLarge} />
                    </AuthIcon>
                  </Responsive>
                  <Responsive maxWidth={559}>
                    <AuthIcon>
                      <YogabotIcon />
                    </AuthIcon>
                  </Responsive>
                  <AuthTitle>
                    <span>{I18n.t('actions.createAccountFree')}</span>
                  </AuthTitle>
                </AuthHeader>
              )}
              {!_.isEmpty(referrerData) && (
                <AuthHeader column>
                  <AuthSeparator />
                  <AuthRefered>
                    <ReferedPicture>
                      <img
                        src={referrerData.pictureUrl || yLogo}
                        alt=""
                        onError={(e) => (e.target.src = yLogo)}
                      />
                    </ReferedPicture>
                    <ReferedTitle>
                      <span style={{ lineHeight: '1.3' }}>
                        {I18n.t('auth.referedTitle', {
                          name: referrerData.name,
                        })}
                      </span>
                    </ReferedTitle>
                  </AuthRefered>
                </AuthHeader>
              )}
              <AuthSeparator />
              <AuthSeparator />
              {(!window.cordova || window.device.platform === 'Android') &&
                this.renderSocialBlock()}
              <AuthSeparator />
              {(!window.cordova || window.device.platform === 'Android') && (
                <AuthLine>
                  <span>{I18n.t('auth.registerOp1')}</span>
                </AuthLine>
              )}
              <ErrorMessage active={hasError} code={codeError} />
              <Form
                name="registerFillForm"
                noValidate
                onSubmit={this.props.handleSubmit(this.register)}
              >
                <AuthSeparator />
                <Field
                  component={CustomInput}
                  placeholder={I18n.t('profile.email')}
                  name="email"
                  fieldClasses="y-input default small"
                  label={I18n.t('auth.emailShort')}
                  restrictions={[{ trim: true }]}
                />
                <Field
                  component={CustomInput}
                  placeholder={''}
                  name="password"
                  type="password"
                  fieldClasses="y-input default small"
                  label={I18n.t('auth.password')}
                />
                <AuthSeparator />
                {!window.cordova && (
                  <AuthSeparator>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LeuyvIUAAAAAH10pcg0jS9x8YHTBasE9sV3rcYo"
                      onChange={this.onChangeCaptcha}
                      className="captcha"
                    />
                  </AuthSeparator>
                )}
                <AuthSeparator>
                  <Field
                    component={CustomCheckBox}
                    name="tos"
                    fieldClasses="y-checkbox small inline-block"
                    label={''}
                  />
                  <label>
                    <Trans i18nKey="auth.acceptTos">
                      <Link to="/legal/tos"></Link>
                    </Trans>
                  </label>
                  <Field
                    component={CustomCheckBox}
                    name="comercialNotifications"
                    fieldClasses="y-checkbox small"
                    label={I18n.t('auth.acceptNotifications')}
                  />
                </AuthSeparator>
                <AuthSeparator>
                  <OButton
                    upper
                    type="submit"
                    disabled={!tos || (!window.cordova && !isCaptchaCorrect)}
                    fluid
                    color="#FFF"
                    primary
                  >
                    <span>{I18n.t('actions.createAccount')}</span>
                  </OButton>
                </AuthSeparator>
                <AuthSeparator>
                  <AuthCTALogin>
                    <span className="labeling regular">
                      {I18n.t('actions.hasAccount')}
                    </span>
                    <span
                      className="labeling regular brand-secondary c-pointer"
                      onClick={this.gotoLogin}
                    >
                      {I18n.t('actions.loginNow')}
                    </span>
                  </AuthCTALogin>
                </AuthSeparator>
                {tos && (
                  <p className="caption regular dark-grey-3">
                    <Trans i18nKey="auth.acceptTosMessage">
                      <Link to="/legal/tos"></Link>
                      <Link to="/legal/privacy"></Link>
                      <Link to="/legal"></Link>
                    </Trans>
                  </p>
                )}
              </Form>
            </RegisterBox>
          </RCols>
          <RCols>
            <AuthMetas>
              <BrandIcon>
                <img src={yLogo} alt="" />
              </BrandIcon>
              <AuthMetaTitle>
                <p
                  dangerouslySetInnerHTML={{ __html: I18n.t('onboard.title1') }}
                ></p>
              </AuthMetaTitle>
              <AuthMetaDescription>
                <p
                  dangerouslySetInnerHTML={{
                    __html: I18n.t('onboard.description1'),
                  }}
                ></p>
              </AuthMetaDescription>
            </AuthMetas>
          </RCols>
        </RegisterWrapper>
      </>
    );
  }
}

const validate = (formValues) => {
  const errors = {};

  if (_.isEmpty(formValues.email)) {
    errors.email = I18n.t('validations.required');
  }

  if (_.isEmpty(formValues.password)) {
    errors.password = I18n.t('validations.required');
  }

  if (
    !_.isEmpty(formValues.email) &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)
  ) {
    errors.email = I18n.t('validations.emailInvalid');
  }

  return errors;
};

const mapStateToProps = (state) => _.cloneDeep(state.registerEmail);

const selector = formValueSelector('registerFillForm');

RegisterView = connect((state) => {
  const tos = selector(state, 'tos');

  return {
    tos,
  };
})(RegisterView);

export default reduxForm({
  form: 'registerFillForm',
  touchOnBlur: true,
  touchOnChange: false,
  validate,
})(
  connect(mapStateToProps, {
    getCustomSessionStats,
    getAlternativePractices,
    registerUser,
    getLastWeekTrainingSeconds,
    getMarketplace,
    getChallenges,
    getBiometricMatrix,
    getRole,
    thirdPartyloginUser,
    openLoader,
    closeLoader,
    openWelcomeModal,
  })(RegisterView)
);
