// Models
import SessionModel from '../data/models/session/session';

// Locales
import I18n from '../i18n';

const changeScreenOrientation = (type, startOnPortrait) => {

    if (window.cordova && !window.screen.orientation.type.includes(type)) {

        if (window.screen.orientation.type.includes('portrait') && type === 'landscape') {

            window.screen.orientation.lock('landscape');
            return true;

        } else if (startOnPortrait && window.screen.orientation.type.includes('landscape') && type === 'portrait') {

            window.screen.orientation.lock('portrait');

        }

    }

    return startOnPortrait;

}

const completeSession = (userSessionId, history, language, nextUrl, callback) => {

    try {

        const song = new Audio(`https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/asanas/sonidos/${ language.substring(1) }/${ language }_fin.mp3`);
        song.addEventListener('ended', () => {

            setTimeout(() => {

                history.push(nextUrl);

            }, 1000);

        }, false);

        song.play();

        SessionModel.userSessionCompleted({ userSessionId }).then(callback);

    } catch (error) {

        console.error('error:userSessionCompleted', error);

    }

}

const showHealthAdvise = (sessionTime, openGenericModal, closeGenericModal) => {

    if (sessionTime === 0) {

        openGenericModal({
            type: 'simple',
            size: 'tiny',
            scrolling: true,
            title: {
                text: I18n.t('messages.info'),
                classes: ['heading-2']
            },
            description: {
                text: I18n.t('practice.healthAdvice'),
                classes: ['labeling', 'regular']
            },
            buttons: [{
                text: I18n.t('practice.healthAdviceAccept'),
                callback: closeGenericModal,
                options: {
                    primary: true,
                    color: '#fff',
                    fluid: true,
                    upper: true
                }
            }]
        });

    }

}

const onRestart = (openGenericModal, closeGenericModal, onPause, restart, onResume) => {

    onPause();

    openGenericModal({
        type: 'simple',
        title: {
            text: I18n.t('messages.toast'),
            classes: ['heading-2']
        },
        description: {
            text: I18n.t('practice.modal.restartPracticsMessage'),
            classes: ['paragraph', 'regular']
        },
        buttons: [{
            text: I18n.t('actions.restart'),
            callback: () => {

                closeGenericModal();
                restart();

            },
            options: {
                primary: true,
                color: '#fff',
                fluid: true,
                upper: true,
                style: {'marginBottom': '10px'}
            }
        }, {
            text : I18n.t('actions.continue'),
            callback: () => {

                closeGenericModal();
                onResume();

            },
            options: {
                primary: true,
                color: '#fff',
                fluid: true,
                upper: true
            }
        }]
    });

}

const createThumbnailByVideoSrc = (src, seekTo = 0.0) => {
    return new Promise((resolve, reject) => {
        // load the file to a video player
        const videoPlayer = document.createElement('video');
        videoPlayer.setAttribute('src', src);
        videoPlayer.setAttribute('crossorigin', 'anonymous');
        videoPlayer.load();
        videoPlayer.addEventListener('error', (ex) => {
          reject('error when loading video file', ex);
        });
        // load metadata of the video to get video duration and dimensions
        videoPlayer.addEventListener('loadedmetadata', () => {
          // seek to user defined timestamp (in seconds) if possible
          if (videoPlayer.duration < seekTo) {
            reject('video is too short.');
            return;
          }
          // delay seeking or else 'seeked' event won't fire on Safari
          setTimeout(() => {
            videoPlayer.currentTime = seekTo;
          }, 200);
          // extract video thumbnail once seeking is complete
          videoPlayer.addEventListener('seeked', () => {
            // define a canvas to have the same dimension as the video
            const canvas = document.createElement('canvas');
            canvas.width = videoPlayer.videoWidth;
            canvas.height = videoPlayer.videoHeight;
            // draw the video frame to canvas
            const ctx = canvas.getContext('2d');
            ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
            // return the canvas image as a blob
            ctx.canvas.toBlob(
              (blob) => {
                resolve(blob);
              },
              'image/jpeg',
              0.5 /* quality */
            );
          });
        });
    });
}

export {
    changeScreenOrientation,
    completeSession,
    showHealthAdvise,
    onRestart,
    createThumbnailByVideoSrc
};