
// Third party libraries
import _ from 'lodash';
import React from 'react';
import Moment from 'moment';
import { connect } from 'react-redux';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import QueryString from 'query-string';

// Routes components
import NotFoundPage from './routes/404';
import Login from './routes/auth/login';
import RecoveryStep1 from './routes/auth/recovery/step1';
import RecoveryStep2 from './routes/auth/recovery/step2';
import RecoveryStep3 from './routes/auth/recovery/step3';
import VerifyEmail from './routes/auth/recovery/verifyEmail';
import RegisterView from './routes/auth/register';
import AccountRemove from './routes/auth/remove';
import DiagnosisChallenge from './routes/diagnosis/challenge';
import DiagnosisChallengeAsana from './routes/diagnosis/challenge/asana';
import DiagnosisChallengeResult from './routes/diagnosis/challenge/result';
import Faq from './routes/faq';
import FaqList from './routes/faq/components/faqList';
import FaqQuestionHandler from './routes/faq/components/FaqQuestionHandler';
import Home from './routes/home';
import MatrixGuide from './routes/home/guide';
import MatrixGuideOption1 from './routes/home/guide/option1';
import MatrixGuideOption2 from './routes/home/guide/option2';
import Profile from './routes/profile';
import Landing from './routes/landing';
import LandingSecondary from './routes/landing/components/testC';
import MatrixLanding from './routes/landing/components/mainLanding/pages/matrix';
import PracticesLanding from './routes/landing/components/mainLanding/pages/practices';
import LegalAdvice from './routes/legal';
import CookiesPolicy from './routes/legal/cookies';
import PrivacyPolicy from './routes/legal/privacy';
import Tos from './routes/legal/tos';
import HandlePractice from './routes/practice';
import HandleGrowPractice from './routes/mode/practice';
import ResultPractice from './routes/practice/result';
import PracticeDiscover from './routes/practiceDiscover';
import PracticeList from './routes/practiceList';
import PracticeDetail from './routes/practiceList/components/practiceDetail';
import UsageGuide from './routes/usageGuide';
import PaymentIncorrect from './routes/auth/payment/incorrect';
import Studio from './routes/studio';
import StudioDetails from './routes/studio/details';
import PremiumPopup from '../ui/components/premiumPopup';
import Payment from './routes/payment';
import Cart from './routes/cart';
import Checkout from './routes/checkout';
import programDetail from './routes/practiceList/components/programDetail';
import Player from './routes/player';
import Step0 from './routes/mode/step0';
import Step1 from './routes/mode/step1';
import Step2 from './routes/mode/step2';
import Step3 from './routes/mode/step3';
import GuideOptions from './routes/mode/guide';
import GuideOptionMatrix from './routes/mode/guide/components/matriz';
import GuideOptionColorMatrix from './routes/mode/guide/components/color';
import Challenge from './routes/mode/challenges/challenge';
import ChallengeAsanas from './routes/mode/challenges/challenge/asanas';
import TechniquesLanding from './routes/landing/components/mainLanding/pages/techniques';
import PlansLanding from './routes/landing/components/mainLanding/pages/plans';
import ResultGrowPractice from './routes/mode/practice/result';
import GrowChallengeResult from './routes/mode/challenges/challenge/result';

// Components
import MeassureListener from './components/meassureListener';
import GenericModal from './components/genericModal';
import WelcomeModal from '../ui/components/welcomeModal';
import ExpiredModal from '../ui/components/expiredModal';

/// Redux
import { getAlternativePractices } from '../redux-store/alternativePractices';
import { getProfile } from '../redux-store/auth';
import { getBiometricMatrix } from '../redux-store/bioMetricMatrix';
import { getChallenges } from '../redux-store/challenges';
import { openLoader, closeLoader } from '../redux-store/loader';
import { getRole } from '../redux-store/role';
import { getLastWeekTrainingSeconds } from '../redux-store/session';
import { getMarketplace } from '../redux-store/cart';
import { getCustomSessionStats } from '../redux-store/customSessionStats';
import { getTransactionMovements } from '../redux-store/walletTransactions';

// History
import History from '../history';

// Locales
import I18n from '../i18n';

const PrivateRoute = ({ ...props }) => QueryString.parse(History.location.search).jwt || window.localStorage.getItem('jwt') ? <Route { ...props } /> : <Redirect to='/auth/login' />;

class App extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            loaded: false
        }

    }

    async componentDidMount() {

        try {

            this.props.openLoader();

            const userIdRef = QueryString.parse(History.location.search).userIdRef;
            if (userIdRef) {

                window.localStorage.setItem('userIdRef', userIdRef);

            }

            const urlJwt = QueryString.parse(History.location.search).jwt;
            const localStorageJwt = window.localStorage.getItem('jwt');
            if (urlJwt && Moment(JSON.parse(atob(urlJwt.split('.')[1])).exp * 1000).diff(Moment(), 'hour') > 1) {

                window.localStorage.setItem('jwt', urlJwt);
                await this.loadUserData();

            } else if (localStorageJwt && Moment(JSON.parse(atob(localStorageJwt.split('.')[1])).exp * 1000).diff(Moment(), 'hour') > 1) {

                await this.loadUserData();

            } else {

                window.localStorage.removeItem('jwt');

            }

        } catch (codeError) {

            console.error('App: codeError', codeError);

        } finally {

            this.setState({ loaded: true });
            this.props.closeLoader();

        }

    }

    loadUserData = async () => {

        this.props.getProfile();
        this.props.getChallenges();
        this.props.getBiometricMatrix();
        this.props.getLastWeekTrainingSeconds();
        this.props.getRole();
        this.props.getAlternativePractices();
        this.props.getMarketplace();
        this.props.getCustomSessionStats();
        this.props.getTransactionMovements();

    }

    render() {

        const { loaded } = this.state;

        return loaded && (
            <I18nextProvider i18n={ I18n }>
                <HashRouter>
                    <Switch>
                        <Route path='/auth/login' exact component={ Login } />
                        <Route path='/auth/payment/incorrect/:data?' exact component={ PaymentIncorrect } />
                        <Route path='/auth/recovery/step/1' exact component={ RecoveryStep1 } />
                        <Route path='/auth/recovery/step/2/:token' exact component={ RecoveryStep2 } />
                        <Route path='/auth/recovery/step/3' exact component={ RecoveryStep3 } />
                        <Route path='/auth/register' exact component={ RegisterView } />
                        <Route path='/auth/remove/:token' exact component={ AccountRemove } />
                        <Route path='/auth/verifyEmail/:token' exact component={ VerifyEmail } />
                        <PrivateRoute path='/diagnosis/challenge/:challengeId' exact component={ DiagnosisChallenge } />
                        <PrivateRoute path='/diagnosis/challenge/:challengeId/asana/:asanaId' exact component={ DiagnosisChallengeAsana } />
                        <PrivateRoute path='/diagnosis/challenge/:challengeId/result' exact component={ DiagnosisChallengeResult } />
                        <Route path='/faq' exact component={ Faq } />
                        <Route path='/faq/list/:categoryId' exact component={ FaqList } />
                        <Route path='/faq/question/:categoryId?' exact component={ FaqQuestionHandler } />
                        <PrivateRoute path='/guide' exact component={ UsageGuide } />
                        <PrivateRoute path='/home' exact component={ Home } />
                        <PrivateRoute path='/home/guide' exact component={ MatrixGuide } />
                        <PrivateRoute path='/home/guide/option/1' exact component={ MatrixGuideOption1 } />
                        <PrivateRoute path='/home/guide/option/2' exact component={ MatrixGuideOption2 } />
                        <Route path='/landing' exact component={ Landing } />
                        <Route path='/legal' exact component={ LegalAdvice } />
                        <Route path='/legal/cookies' exact component={ CookiesPolicy } />
                        <Route path='/legal/privacy' exact component={ PrivacyPolicy } />
                        <Route path='/legal/tos' exact component={ Tos } />
                        <PrivateRoute path='/practiceList' exact component={ PracticeList } />
                        <PrivateRoute path='/practiceList/detail/:practiceId' exact component={ PracticeDetail } />
                        <PrivateRoute path='/practice/:sequence' exact component={ HandlePractice } />
                        <PrivateRoute path='/practice/result/sequence' exact component={ ResultPractice } />
                        <PrivateRoute path='/practiceList/program/:programId' exact component={ programDetail } />
                        <PrivateRoute path='/practiceDiscover' component={ PracticeDiscover } />
                        <PrivateRoute path='/profile' component={ Profile } />
                        <PrivateRoute path='/payment/:data' component={ Payment } />
                        <PrivateRoute path='/studio' exact component={ Studio } />
                        <PrivateRoute path='/studio/details/:asanaId' exact component={ StudioDetails } />
                        <PrivateRoute path='/cart' exact component={ Cart } />
                        <PrivateRoute path='/checkout' exact component={ Checkout } />\
                        <PrivateRoute path='/mode/grow/guide' exact component={ GuideOptions } />
                        <PrivateRoute path='/mode/grow/guide/matrix' exact component={ GuideOptionMatrix } />
                        <PrivateRoute path='/mode/grow/guide/color' exact component={ GuideOptionColorMatrix } />
                        <PrivateRoute path='/mode/grow/step/0' exact component={ Step0 } />
                        <PrivateRoute path='/mode/grow/step/1' exact component={ Step1 } />
                        <PrivateRoute path='/mode/grow/step/2' exact component={ Step2 } />
                        <PrivateRoute path='/mode/grow/step/3' exact component={ Step3 } />
                        <PrivateRoute path='/mode/grow/step/2/challenge/:challengeId' exact component={ Challenge } />
                        <PrivateRoute path='/mode/grow/step/2/challenge/:challengeId/asana/:asanaId' exact component={ ChallengeAsanas } />
                        <PrivateRoute path='/mode/grow/step/2/challenge/:challengeId/result' exact component={ GrowChallengeResult } />
                        <PrivateRoute path='/mode/grow/practice/:sequence' exact component={ HandleGrowPractice } />
                        <PrivateRoute path='/mode/grow/result/sequence' exact component={ ResultGrowPractice } />
                        <Route path='/player/:url' exact component={ Player } />
                        <Route path='/cv1' exact component={ LandingSecondary } />
                        <Route path='/landing/plans' exact component={ PlansLanding } />
                        <Route path='/landing/studio' exact component={ TechniquesLanding } />
                        <Route path='/landing/practices' exact component={ PracticesLanding } />
                        <Route path='/landing/biometricMatrix' exact component={ MatrixLanding } />
                        <Route path='/' exact component={ Landing } />
                        <Route path='/404' component={ NotFoundPage } />
                        <Route path='*' component={ NotFoundPage } />
                    </Switch>
                </HashRouter>
                { window.localStorage.getItem('jwt') && <PremiumPopup /> }
                <WelcomeModal />
                { window.localStorage.getItem('jwt') && <ExpiredModal /> }
                <ToastContainer autoClose={ 2000 } />
                <GenericModal />
                <MeassureListener />
            </I18nextProvider>
        );

    }

}

const mapStateToProps = state => _.cloneDeep(state.challenges);

export default connect(mapStateToProps, { getCustomSessionStats, closeLoader, getAlternativePractices, getBiometricMatrix, getChallenges, getLastWeekTrainingSeconds, getProfile, getRole, openLoader, getMarketplace, getTransactionMovements })(App);