import { useCallback, useMemo, useState } from 'react';
import ProgressBarThumbnails from './progressBarThumbnails';

/**
 * 
 * @param {Number} startLimitPercent Limita en que porcentaje inicial de la práctica se debe limitar al preview para que no salga de la pantalla
 * @param {Number} endLimitPercent Limita en que porcentaje final de la práctica se debe limitar al preview para que no salga de la pantalla
 * @param {Number} startLimit Determina en que posición se debe parar el preview después que pase el limite de inicio (startLimitPercent)
 * @param {Number} endLimit Determina en que posición se debe parar el preview después que pase el limite de final (endLimitPercent)
 * @param {Function} onForwardAndBackward Función que se ejecuta cuando se hace click en la barra de progreso 
 * @returns 
 */
const ProgressBarSeeker = ({
  globalProgress,
  globalSequenceTime,
  videos,
  startLimitPercent,
  endLimitPercent,
  startLimit,
  endLimit,
  onForwardAndBackward,
}) => {
  const [currPreviewPositionX, setCurrPreviewPositionX] = useState(0);

  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const [currPercentage, setCurrPercentage] = useState(0);

  const asanas = useMemo(
    () => videos.filter((video) => video?.duration && video?.loop === true),
    [videos]
  );

  const totalDuration = useMemo(() => {
    let count = 0;

    if (asanas.length === 0) {
      return 0;
    }

    asanas.forEach((asana) => {
      count += asana.duration || 0;
    });

    return count;
  }, [asanas]);

  const asanasWithPercentage = useMemo(() => {
    if (asanas.length === 0) {
      return [];
    }

    const result = [];

    let count = 0;

    asanas.forEach((asana) => {
      const percentage = (asana.duration / totalDuration) * 100;

      // Tuve que restar y sumar 0.5 en cada caso para dar el margen de error que pueda tener javascript
      const percentageStart = ((count / totalDuration) * 100) - 0.5;
      const percentageEnd = (percentageStart + percentage) + 0.5;

      count += asana.duration;

      result.push({
        ...asana,
        percentageStart,
        percentageEnd,
      });
    });

    return result;
  }, [asanas, totalDuration]);

  const thumbnails = useMemo(() => {
    const thumbnails = [];

    asanasWithPercentage.forEach(async (asana) => {
      const cover = asana.image;

      thumbnails.push({
        cover,
        index: asana.currentAsanaIndex,
        percentageStart: asana.percentageStart,
        percentageEnd: asana.percentageEnd,
        webm: asana.webm,
      });
    });

    return thumbnails;
  }, [asanasWithPercentage]);

  const renderThumbnails = useMemo(() => {
    if (!thumbnails) return null;

    return thumbnails.map((thumbnail) => {
      const i = thumbnail.index;
      return (
        <ProgressBarThumbnails
          key={'video_' + i}
          index={i}
          display={
            currPercentage >= thumbnail.percentageStart &&
            currPercentage < thumbnail.percentageEnd
              ? 'block'
              : 'none'
          }
          cover={thumbnail.cover}
          originalSrc={thumbnail.webm}
        />
      );
    });
  }, [currPercentage, thumbnails]);

  const getAsanaByPercent = useCallback(
    (percent = 0) =>
      asanasWithPercentage.find(
        (elem) =>
          percent >= elem.percentageStart && percent < elem.percentageEnd
      ),
    [asanasWithPercentage]
  );

  const handleOnMouseMove = (event) => {
    const element = event.currentTarget;
    const boundingRect = element.getBoundingClientRect();
    const mouseX = event.clientX - boundingRect.left;

    const width = boundingRect.width;
    const percentage = (mouseX / width) * 100;

    if (percentage <= startLimitPercent) {
      setCurrPreviewPositionX(width * startLimit);
    } else if (percentage >= endLimitPercent) {
      setCurrPreviewPositionX(width - width * endLimit);
    } else {
      setCurrPreviewPositionX(mouseX);
    }

    setIsPreviewVisible(true);

    setCurrPercentage(percentage);
  };

  const handleOnMouseLeave = () => {
    setIsPreviewVisible(false);
  };

  const handleOnClick = (event) => {
    const element = event.currentTarget;
    const boundingRect = element.getBoundingClientRect();
    const mouseX = event.clientX - boundingRect.left;

    const width = boundingRect.width;
    const percentage = (mouseX / width) * 100;

    const asana = getAsanaByPercent(percentage);

    if (onForwardAndBackward) {
      const index = videos.findIndex(
        (video) => video.currentAsanaIndex === asana.currentAsanaIndex
      );

      onForwardAndBackward(index);
    }
  };

  return (
    <div className="progress-bar">
      <div
        className="progress-bar-preview"
        id="progress-bar-preview"
        style={{
          left: `calc(${currPreviewPositionX}px + 5%)`,
          transition:
            currPercentage <= startLimitPercent + 5 || currPercentage >= endLimitPercent - 5
              ? 'left 0.5s ease'
              : undefined,
        }}
        hidden={!isPreviewVisible}
      >
        {renderThumbnails}
      </div>
      <div
        className="progress-bar-seeker"
        onMouseMove={handleOnMouseMove}
        onMouseLeave={handleOnMouseLeave}
        onClick={handleOnClick}
      >
        <div
          className="progress-inner"
          style={{ width: (globalProgress / globalSequenceTime) * 100 + '%' }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBarSeeker;
