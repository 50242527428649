// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

// Config
import Config from '../../../config';

// Redux
import { getRole } from '../../../redux-store/role';

// Components
import StripeCheckoutForm from '../../components/payments/stripe';
import GradientNav from '../../components/navbar/gradient';
import SubMenu from '../../components/navbar/submenu';
import TopBar from '../../components/navbar/topbar';
import Footer from '../../components/footer';

// Utils
import { TrackingService } from '../../../utils/TrackingService';

// Locales
import I18n from '../../../i18n';

// Styles
import './payment.scss';

// Assets
import correctImage from '../../assets/img/correct.svg';

// Models
import ProductModel from '../../../data/models/product/product';

const stripePromise = loadStripe(Config.stripe.publicKey);

class Payment extends Component {

    constructor(props) {

        super(props)

        this.state = {
            amount: {},
            correctPayment: false,
            type: 'annualPayment',
            discount: 0,
            periodic: false
        };

    }

    componentDidMount() {

        let uncryptedData = window.atob(this.props.match.params.data).split('/');
        this.setState({ amount: JSON.parse(uncryptedData[0]), description: uncryptedData[1], type: uncryptedData[2], periodic: JSON.parse(uncryptedData[3]) });

    }

    onSuccess = affiliation => {

        this.setState({ correctPayment: true });

        const { amount, discount } = this.state;
        const { currency } = this.props;

        TrackingService.registerEvent('Purchase', 'purchase', {
            affiliation,
            currency,
            value: amount[currency] - discount,
            item_list_name: `Suscripción premium ${ this.state.type === 'annualPayment' ? 'anual' : 'mensual' }`
        });   
        
        // Aquí hacer la petición a la API de yogabot-school para que le asigne todos los productos del apartado Estudio por ser usuario premium
        this.updateUserSchoolProducts();

        this.updateStatusAfterCorrectPayment();

    }

    updateStatusAfterCorrectPayment = async () => {

        this.props.getRole();

    }

    updateUserSchoolProducts = () => {
        try {
            ProductModel.getProducts().then(async result=>{
                const products = result.data;

                if(!products || products.length === 0) return;

                const publishedProducts = products.filter(product => product.published);

                if(!publishedProducts || publishedProducts.length === 0) return;

                const productIds = publishedProducts.map(product => product._id);

                await ProductModel.setUserSchoolProducts(productIds);
            });
        } catch (error) {

            console.error(error)   

        }
    }

    onError = error => {

        this.props.history.push(`/auth/payment/incorrect/${this.props.match.params.data}`);

    }

    setAmountBlock = (amount, currency) => {

        switch(currency) {

            case 'USD':

                return `$${ amount.toFixed(2) }`;

            case 'EUR':

                return `${ amount.toFixed(2) }€`;

            default:

                return '';

        }

    }

    onCancel = data => {}

    render() {

        const { currency} = this.props;

        const { amount, description, correctPayment, type, periodic, discount } = this.state;

        return _.size(amount) && (
            <React.Fragment>
                <GradientNav active="" { ...this.props } />
                <div className="payments-wrapper">
                    <TopBar { ...this.props } text={ I18n.t('payment.payment') } callback={ () => this.props.history.goBack() }/>
                    <div className="inner">
                        { !correctPayment &&
                            <React.Fragment>
                                <h1>{ I18n.t('payment.choosePaymentOption') }</h1>
                                <div className="stripe-block">
                                    <div className="infoPayMent">
                                        <div className="icon-pay">
                                            <i className="f-icon-credit-card"></i>
                                        </div>
                                        <div className="pay-info">
                                            <span className="title-price">{ I18n.t('payment.total') }</span>
                                            <span className="info-price">{ this.setAmountBlock(amount[currency] - discount, currency) }</span>
                                        </div>
                                    </div>
                                    <div className="blocks-separator">
                                        <span className="title">{ I18n.t('payment.payCreditOrDebit') }</span>
                                        <hr className="hr-separator"></hr>
                                        <Elements stripe={ stripePromise }>
                                            <ElementsConsumer>
                                                { ({ stripe, elements }) => (
                                                    <StripeCheckoutForm
                                                        stripe={ stripe }
                                                        elements={ elements }
                                                        amount={ amount[currency] }
                                                        currency={ currency }
                                                        periodic={ periodic }
                                                        description={ description }
                                                        type={ type }
                                                        onSuccesDiscountCode={ discount => this.setState({ discount }) }
                                                        onSuccess={ () => this.onSuccess('Stripe') }
                                                        onError={ this.onError }
                                                        onCancel={ this.onCancel }
                                                    />
                                                ) }
                                            </ElementsConsumer>
                                        </Elements>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                        {correctPayment &&
                            <React.Fragment>
                                <div className="correct-payment-wrapper">
                                    <div className="content-payment">
                                        <div className="icon-c">
                                            <img src={ correctImage } alt="" />
                                        </div>
                                        <p>{ I18n.t('checkout.correctPayment') }</p>
                                    </div>
                                </div>
                            </React.Fragment>
                        }
                    </div>
                    <Footer { ...this.props } type="branded"/>
                </div>
                <SubMenu active="" { ...this.props } />
            </React.Fragment>
        );

    }

}

const mapStateToProps = state => {

    return {
        userSession: _.get(state, 'auth.userSession', {}),
        currency: _.get(state, 'auth.userSession.config.currency', {})
    };

};

export default connect(mapStateToProps, { getRole })(Payment);