// Third party libraries
import _ from 'lodash'
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

// Redux store
import { getMarketplace } from '../../../redux-store/cart';
import { openLoader, closeLoader } from '../../../redux-store/loader';
import { openGenericModal, closeGenericModal } from '../../../redux-store/genericModal';

// Config
import Config from '../../../config';

// Components
import GradientNav from '../../components/navbar/gradient';
import SubMenu from '../../components/navbar/submenu';
// import { NumericFormat } from '../../components/numericFormat/NumericFormat';
import InAppPurchaseButton from '../../components/payments/inAppPurchase';
import StripeCheckoutForm from '../../components/payments/stripe';
import TopBar from '../../components/navbar/topbar';

// Styled Components
// import OButton from '../../styled/components/button';

// Models
import PaymentModel from '../../../data/models/payment/payment';
import StatsModel from '../../../data/models/stats/stats';

// Utils
import { TrackingService } from '../../../utils/TrackingService';

// Locales
import I18n from '../../../i18n';

// Assets
import correctImage from '../../assets/img/correct.svg';

const stripePromise = loadStripe(Config.stripe.publicKey);

class Checkout extends Component {

    constructor(props) {

        super(props);

        this.state = {
            correctPayment: false,
            type: 'product',
            description: I18n.t('checkout.productsBuyed'),
            userWallet: {},
            exchangeInUSD: 0,
            ygbCurrentValueInEUR: 0
        };

    }

    componentWillUnmount() {

        const { closeGenericModal } = this.props;

        closeGenericModal();

    }

    async componentDidMount() {

        const { openLoader, closeLoader } = this.props;

        try {

            openLoader();
            const responseUserBalance = await PaymentModel.getUserWalletBalance();
            const responseYgbCurrentValue = await PaymentModel.getStagesInfo();
            const exchangeUSD = (await StatsModel.getExchange('USD')).data;
            this.setState({ userWallet: responseUserBalance.data, ygbCurrentValueInEUR: responseYgbCurrentValue.data, exchangeInUSD: exchangeUSD.value });

        } catch (error) {

            console.error(error);

        } finally {

            closeLoader();

        }

    }

    setAmountBlock = (amount, currency) => {

        switch(currency) {

            case 'USD':

                return `$${ amount.toFixed(2) }`;

            case 'EUR':

                return `${ amount.toFixed(2) }€`;

            default:

                return '';

        }

    }

    onError = () => {

        const { openGenericModal, closeGenericModal } = this.props;
        this.props.closeLoader();

        openGenericModal({
            type: 'simple',
            title:{
                text: I18n.t('messages.alert'),
                classes: ['heading-2']
            },
            description:{
                text: I18n.t('auth.paymentErrorSubtitle'),
                classes: ['paragraph', 'regular']
            },
            buttons:[
                {
                    text: I18n.t('actions.understood'),
                    callback: ()=> {

                        closeGenericModal();

                    },
                    options: {
                        primary: true,
                        color: '#fff',
                        fluid: true,
                        upper: true
                    }
                }
            ]
        });

    }

    onSuccess = (productsMap, amount, currency, webAffiliation) => {

        const { openGenericModal, closeGenericModal } = this.props;

        const affiliation = window.cordova ? window.device.platform === 'Android' ? 'Google Play' : 'Apple Store' : webAffiliation;

        TrackingService.registerEvent('Purchase', 'purchase', {
            affiliation,
            currency,
            value: amount,
            items: productsMap,
            item_list_name: 'Compra de productos'
        });

        window.localStorage.removeItem('itemsAdded');
        this.props.getMarketplace();
        this.props.closeLoader();
        openGenericModal({
            type: 'simple',
            title: {
                text: I18n.t('messages.toast'),
                classes: ['heading-2']
            },
            description: {
                text: I18n.t('checkout.correctPayment'),
                classes: ['paragraph', 'regular']
            },
            buttons:[{
                text: I18n.t('actions.understood'),
                callback: () => {

                    closeGenericModal();
                    this.props.history.push('/studio');

                },
                options: {
                    primary: true,
                    color: '#fff',
                    fluid: true,
                    upper: true
                }
            }]
        });

    }

    onPayWithYGB = async (productsMapYGB, amount, userSessionId) => {

        try {

            this.props.openLoader();
            await PaymentModel.createUserPayment({
                amount: amount,
                currency: 'YGB',
                description: I18n.t('checkout.productsBuyed'),
                type: 'product',
                platform: 'yogabot',
                payerId: userSessionId,
                orderId: 'Yogabot',
                products: productsMapYGB
            });

            this.onSuccess(productsMapYGB, amount, 'YGB', 'YGB');

        } catch (error) {

            console.error(error);
            this.onError();

        }

    }

    onInAppPurchaseSuccess = async (productsMap, receipt, transactionId, signature) => {

        try {

            const { currency } = this.props;

            await PaymentModel.createUserPayment({
                amount: Config.inapppurchase.schoolProducts[_.size(productsMap)].price[currency][window.device.platform],
                currency,
                type: 'product',
                description: I18n.t('checkout.productsBuyed'),
                platform: window.device.platform,
                platformReceipt: receipt,
                orderId: transactionId,
                products: productsMap,
                signature
            });

            this.onSuccess(productsMap, Config.inapppurchase.schoolProducts[_.size(productsMap)].price[currency][window.device.platform], currency);

        } catch (error) {

            console.error(error);
            this.onError();

        }

    };

    onCancel = data => {}

    // renderYGBPaymentBlock = (userBalance, totalInYGB, amount, currency, productsMap, userSessionId) => {

    //     //OPCIÓN 1: Tiene saldo disponible y suficiente.
    //     if (userBalance > 0 && userBalance >= totalInYGB) {

    //         return (
    //             <>
    //                 <p className="caption regular">Saldo disponible: <strong><NumericFormat value={userBalance} type="token" suffix="YGB"/></strong></p>
    //                 <p className="caption regular">Total a pagar: <strong><NumericFormat value={totalInYGB} type="token" suffix="YGB"/> ({this.setAmountBlock(amount[currency] * 0.90, currency)})</strong></p>
    //                 <p className="caption regular"><strong>Ahorras <NumericFormat value={amount[currency] * 0.10} type={currency.toLowerCase()} /></strong></p>
    //                 <OButton type="button" primary color="#fff" onClick={() => this.onPayWithYGB(productsMap, totalInYGB, userSessionId) }>
    //                     <span>{I18n.t('actions.payWithYGB')}</span>
    //                 </OButton>
    //             </>
    //         );

    //     }

    //     //OPCIÓN 2: Tiene saldo disponible, pero insuficiente.
    //     if (userBalance > 0 && userBalance < totalInYGB) {

    //         return (
    //             <>
    //                 <p className="caption regular">Saldo disponible: <strong><NumericFormat value={userBalance} type="token" suffix="YGB"/></strong></p>
    //                 <p className="caption regular">Total a pagar: <strong><NumericFormat value={totalInYGB} type="token" suffix="YGB"/></strong></p>
    //                 <p className="caption regular"><strong>Saldo insuficiente.</strong> Agrega saldo en YGB y <strong>ahorra <NumericFormat value={amount[currency] * 0.10} type={currency.toLowerCase()} /></strong> en esta compra</p>
    //                 <OButton type="button" primary color="#fff" onClick={() => this.props.history.push(`/profile/wallet/packages`)}>
    //                     <span>{I18n.t('actions.addYgbBalance')}</span>
    //                 </OButton>
    //             </>
    //         );

    //     }

    //     //OPCIÓN 3: No tiene saldo disponible. Sin saldo
    //     if (userBalance <= 0) {

    //         return (
    //             <>
    //                 <p className="caption regular">No tienes saldo en YGB</p>
    //                 <p className="caption regular">Total a pagar: <strong><NumericFormat value={totalInYGB} type="token" suffix="YGB"/></strong></p>
    //                 <p className="caption regular">Agrega saldo en YGB y <strong>ahorra <NumericFormat value={amount[currency] * 0.10} type={currency.toLowerCase()} /></strong> en esta compra</p>
    //                 <OButton type="button" primary color="#fff" onClick={() => this.props.history.push(`/profile/wallet/packages`)}>
    //                     <span>{I18n.t('actions.buyYGB')}</span>
    //                 </OButton>
    //             </>
    //         );

    //     }

    // }

    render() {

        const { products, userSession, currency } = this.props;
        const { description, correctPayment, type, /*userWallet,*/ ygbCurrentValueInEUR, exchangeInUSD } = this.state;
        const amount = { USD: 0, EUR: 0 };
        const productsMap = [];
        const productsMapYGB = [];
        const productsAdded = _.filter(products, ({added, buyed}) => added && !buyed);
        const currentMarketYgbPriceInUsd = currency === 'USD' ? ygbCurrentValueInEUR.price * exchangeInUSD : ygbCurrentValueInEUR.price;

        _.each(productsAdded, ({prices, discountedPrices = {},  _id}) => {

            amount.USD += !_.isEmpty(discountedPrices) ? +discountedPrices.USD : +prices.USD;
            amount.EUR += !_.isEmpty(discountedPrices) ? +discountedPrices.EUR : +prices.EUR;
            productsMap.push({ productId: _id, price: !_.isEmpty(discountedPrices) ? +discountedPrices[currency] : +prices[currency] });
            productsMapYGB.push({
                productId: _id,
                price: !_.isEmpty(discountedPrices) ?
                _.round( (+discountedPrices[currency] * 0.90) / (currentMarketYgbPriceInUsd) ) :
                _.round( (+prices[currency] * 0.90 ) / (currentMarketYgbPriceInUsd) )
            });

        });

        //Total a pagar en YGB aplicando el 10 de descuento, teniendo en cuenta el valor actual del mercado del YGB tanto en EUR como en USD
        // const totalAmountInYGB = _.sumBy(productsMapYGB, 'price');

        return (_.size(productsMap) > 0) && (
            <React.Fragment>
                <GradientNav active="" {...this.props} />
                <div className="payments-wrapper">
                    <TopBar callback={() => this.props.history.goBack()} {...this.props} text={I18n.t('payment.payment')} />
                    <div className="inner">
                        { !correctPayment &&
                            <React.Fragment>
                                <h1>{I18n.t('payment.choosePaymentOption')}</h1>
                                <div className="stripe-block">
                                    <div className="infoPayMent">
                                        <div className="icon-pay">
                                            <i className="f-icon-credit-card"></i>
                                        </div>
                                        <div className="pay-info">
                                            <span className="title-price">{I18n.t('payment.total')}</span>
                                            <span className="info-price">{this.setAmountBlock(amount[currency], currency)}</span>
                                        </div>
                                    </div>
                                    { !window.cordova &&
                                        <React.Fragment>
                                            <div className="blocks-separator">
                                                <span className="title">{I18n.t('payment.payCreditOrDebit')}</span>
                                                <hr className="hr-separator"></hr>
                                                <Elements stripe={ stripePromise }>
                                                    <ElementsConsumer>
                                                        { ({ stripe, elements }) => (
                                                            <StripeCheckoutForm
                                                                stripe={ stripe }
                                                                elements={ elements }
                                                                amount={ amount[currency] }
                                                                currency={ currency }
                                                                periodic={ false }
                                                                description={ description }
                                                                type={ type }
                                                                onSuccess={ () => this.onSuccess(productsMap, amount[currency], currency, 'Stripe') }
                                                                onError={ this.onError }
                                                                onCancel={ this.onCancel }
                                                                products={ productsMap }
                                                            />
                                                        ) }
                                                    </ElementsConsumer>
                                                </Elements>
                                            </div>
                                        </React.Fragment>
                                    }
                                    { window.cordova &&
                                        <div className="blocks-separator">
                                            <div className="payapal-box">
                                                <InAppPurchaseButton
                                                    product={ window.device.platform === 'Android' ? Config.inapppurchase.schoolProducts[_.size(productsMap)].id.toLowerCase() : Config.inapppurchase.schoolProducts[_.size(productsMap)].id }
                                                    payer={ _.get(userSession, 'email', '') }
                                                    forceClick={ false }
                                                    onSuccess={ (receipt, transactionId, signature) => this.onInAppPurchaseSuccess(productsMap, receipt, transactionId, signature) }
                                                    onError={ this.onError }
                                                />
                                            </div>
                                        </div>
                                    }
                                    {/* <div className="blocks-separator">
                                        <span className="title">{I18n.t('payment.payWithYGB')}</span>
                                        <hr className="hr-separator"></hr>
                                        {
                                            this.renderYGBPaymentBlock(userWallet.balance || 0, totalAmountInYGB, amount, currency, productsMapYGB, userSession._id)
                                        }
                                    </div> */}
                                </div>
                            </React.Fragment>
                        }
                        { correctPayment &&
                            <div className="correct-payment-wrapper">
                                <div className="content-payment">
                                    <div className="icon-c">
                                        <img src={correctImage} alt="" />
                                    </div>
                                    <p>{I18n.t('messages.paymentCorrect')}</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <SubMenu active="" {...this.props} />
            </React.Fragment>
        );

    }

}

const mapStateToProps = state => {

    return {
        products: _.get(state, 'cart.products', []),
        userSession: _.get(state, 'auth.userSession', {}),
        role: state.role,
        currency: _.get(state, 'auth.userSession.config.currency', {})
    };

};

export default connect(mapStateToProps, { getMarketplace, openLoader, closeLoader, openGenericModal, closeGenericModal })(Checkout);