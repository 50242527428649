// Third party libraries
import { Route } from 'react-router-dom';

// Components
import GradientNav from '../../components/navbar/gradient';
import SubMenu from '../../components/navbar/submenu';
import UserAccount from './components/userAccount';
import UserObjectives from './components/userObjectives';
import UserSecurity from './components/userSecurity';
import UserSuscription from './components/userSuscription';
import UserPromotion from './components/userPromotion';
import UserPlans from './components/userPlans';
// import UserWallet from './components/userWallet';
// import WalletPackages from './components/wallet/WalletPackages';
// import WalletCheckout from './components/wallet/WalletCheckout';
// import WalletCheckoutResult from './components/wallet/WalletCheckoutResult';
// import WalletTransactionHistory from './components/wallet/WalletTransactionHistory';
import WalletLinkRef from './components/wallet/WalletLinkRef';
// import WalletSend from './components/wallet/WalletSend';
// import WalletRecieve from './components/wallet/WalletRecieve';

const Profile = props => {

    return <>
        <GradientNav active='profile' { ...props } />
        <Route path='/profile/account' exact component={ UserAccount } />
        <Route path='/profile/objectives' exact component={ UserObjectives } />
        <Route path='/profile/security' exact component={ UserSecurity } />
        <Route path='/profile/suscription' exact component={ UserSuscription } />
        <Route path='/profile/promotion' exact component={ UserPromotion } />
        <Route path='/profile/plans' exact component={ UserPlans } />
        {/* <Route path='/profile/wallet' exact component={ UserWallet } />
        <Route path='/profile/wallet/packages' exact component={ WalletPackages } />
        <Route path='/profile/wallet/checkout' exact component={ WalletCheckout } />
        <Route path='/profile/wallet/checkout/result/:status' exact component={ WalletCheckoutResult } />
        <Route path='/profile/wallet/history' exact component={ WalletTransactionHistory } />*/
        <Route path='/profile/wallet/reflink' exact component={ WalletLinkRef } />
        /*<Route path='/profile/wallet/send' exact component={ WalletSend } />
        <Route path='/profile/wallet/receive' exact component={ WalletRecieve } /> */ }
        <SubMenu active='profile' { ...props } />
    </>;

}

export default Profile;
