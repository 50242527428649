import React from 'react';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import OButton from '../../../../styled/components/button';
import DiscountElement from '../discountElement';
import { toMMSS } from '../../../../../utils/formatTime';
import I18n from '../../../../../i18n';
import { CardItem, CardItemContent, CardItemImage, CardItemWrapperButton, CardItemWrappImage, CardItemTime, CardItemTitle, CardItemPrice, CardItemBuyed } from './styles';

/***
 * * STUDIO CARD COMPONENT
 *
 * @param _id String
 * @param title String
 * @param buyed Boolean
 * @param added Boolean
 * @param discount String
 * @param imageUrl String
 * @param duration String
 * @param currency String
 * @param prices Object
 * @param discountedPrices Object
 * @param context String { slider , default grid }
 * @param mainAction Function
 * @param onAddToCart Function
 */

const setCurrencyView = (currency, price) => currency === 'USD' ? <span>${ price[currency]?.toFixed(2) }</span> : <span>{ price[currency]?.toFixed(2) }€</span>

export default function Card({_id, title, context = 'grid', buyed, added, discountedPrices = {}, discount, imageUrl, duration, currency, prices , ...restProps }) {

    return (
        <CardItem context={context} key={_id} onClick={(e) => {restProps.mainAction(e, _id)}}>
            {(!buyed && !_.isEmpty(discountedPrices) ) &&
                <DiscountElement floating>
                    <span className='paragraph upper'>{discount}% DTO</span>
                </DiscountElement>
            }
            <CardItemWrappImage>
                <CardItemImage>
                    <img src={imageUrl} alt='' />
                </CardItemImage>
            </CardItemWrappImage>
            <CardItemContent>
                <CardItemTime className='caption regular'><i className='f-icon-icono_17'></i>{toMMSS(duration)}</CardItemTime>
                <CardItemTitle className='paragraph regular'>{title}</CardItemTitle>
                { (!buyed && _.isEmpty(discountedPrices)) && <CardItemPrice className='paragraph'>{setCurrencyView(currency, prices)}</CardItemPrice> }
                { (!buyed && !_.isEmpty(discountedPrices)) &&
                    <DiscountElement.DiscountPrice>
                        <p className='caption regular brand-primary-extra-light' style={{margin: 0}}>{I18n.t('studio.previousPrice')} {setCurrencyView(currency, prices)}</p>
                        <p className='paragraph' style={{margin: 0}}>{I18n.t('studio.currentPrice')} {setCurrencyView( currency, discountedPrices )}</p>
                    </DiscountElement.DiscountPrice>
                }
            </CardItemContent>
            <CardItemWrapperButton>
                {!buyed &&
                <OButton
                    upper
                    className='item-btn'
                    fluid
                    primary={!added}
                    selected={added}
                    icon
                    size='tiny'
                    color={added ? '#1bb89f' : '#fff'}
                    type='button'
                    labelPosition='left'
                    onClick={(e)=> {restProps.onAddToCart(e, added, _id)}} >
                        {added ?
                        <React.Fragment>
                            <Icon name='check' />
                            <span>{I18n.t('studio.added')}</span>
                        </React.Fragment>
                            :
                            <span>{I18n.t('actions.add')}</span>
                        }
                </OButton>
                }
                {buyed && <CardItemBuyed><i className='f-icon-icono_4'></i><span>{I18n.t('status.available')}</span></CardItemBuyed>}
            </CardItemWrapperButton>
        </CardItem>
    )
}
