import { useCallback, useState } from 'react';
import { createThumbnailByVideoSrc } from '../../../../utils/practice';
import I18n from '../../../../i18n';

const ProgressBarThumbnails = ({ index, display, cover, originalSrc }) => {
  const [localCover, setLocalCover] = useState(cover);
  const [isLoading, setIsLoading] = useState(false);

  const styles = {
    display,
    backgroundColor: 'black',
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  };

  const getAsanaThumbnails = useCallback(
    async (src, seekTo = 0.0) => await createThumbnailByVideoSrc(src, seekTo),
    []
  );

  // Se tuvo que hacer esto porque en ocasiones se pierde la referencia del cover creado y hay que volver a crearlo
  const handleOnError = async () => {
    setIsLoading(true);
    const coverBlob = await getAsanaThumbnails(originalSrc, 1.0);
    const cover = URL.createObjectURL(coverBlob);
    setLocalCover(cover);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <p
          style={{
            ...styles,
            color: 'white',
            textAlign: 'center',
            alignContent: 'center',
          }}
        >
          {I18n.t('practice.loadingPreview')}
        </p>
      ) : (
        <img
          style={styles}
          id={'video_' + index}
          alt="asana cover"
          src={localCover}
          onError={handleOnError}
        ></img>
      )}
    </>
  );
};

export default ProgressBarThumbnails;
